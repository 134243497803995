import { Nav } from "../components/navbar";
import '../styles/notfound.css'

export function NotFound() {
    return (
        <>
            <Nav/>
            <div className="container-not-found">
                Este dibujo no existe :-c
            </div>

        </>
    )
}
