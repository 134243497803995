import '../styles/home.css'
import { Nav } from "../components/navbar";
import Carousel from "../components/Carousel";



export function Home({data}) {




    return (
        <div>
             <Nav />

             <Carousel />
                
            
        </div>
    )
}
